<!--团队管理：人员管理-->
<template>
  <div class="">
    <div class="page-container">
      <div class="section">
        <!--搜索栏-->
        <div class="control-box1 h2Style">
          <div class="cb-left">
            <el-input
                v-model.trim="form.name"
                clearable
                placeholder="姓名/手机号"
                style="width: 200px;"
                @clear="form.name = undefined"
                @keyup.enter.native="handleFilter"
            />
            <el-form ref="form" class="control-item" :model="form" label-width="80px">
              <el-form-item label="账号状态：" label-width="100px" class="form-item">
                <el-select v-model="form.state" clearable>
                  <el-option
                      v-for="item in state"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-form>
            <el-button
                class="control-item"
                icon="el-icon-search"
                type="primary"
                @click="handleFilter"
            >
              搜索
            </el-button>
          </div>

          <div class="cb-right">
            <el-button
                type="primary"
                icon="el-icon-plus"
                @click="addTeamUserShow()"
            >
              添加
            </el-button>
          </div>
        </div>
        <!--表格-->
        <div class="table-box" style="width: 100%">
          <el-table
              v-loading="listLoading"
              :data="teamUser"
              element-loading-text="Loading"
              border
              highlight-current-row
              style="width: 100%"
          >
            <!--昵称-->
            <el-table-column label="姓名" align="center" width="150">
              <template slot-scope="scope">
                {{ scope.row.cname }}
              </template>
            </el-table-column>

            <!--手机号-->
            <el-table-column label="手机号" align="center" width="150">
              <template slot-scope="scope">
                <span>{{ scope.row.cphone }}</span>
              </template>
            </el-table-column>
            <!--角色-->
            <el-table-column label="账号状态" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.state === 0">启用</span>
                <span v-else>停用</span>
              </template>
            </el-table-column>
            <el-table-column prop="created_at" label="添加时间" align="center" width="170">
              <template slot-scope="scope">
                <span>{{ scope.row.dcreateDate }}</span>
              </template>
            </el-table-column>
            <!--注册时间-->
            <el-table-column prop="created_at" label="上次登录时间" align="center" width="170">
              <template slot-scope="scope">
                <span>{{ scope.row.lastDate }}</span>
              </template>
            </el-table-column>
            <!--操作-->
            <!--        v-if="managePermission"-->
            <el-table-column label="操作" align="center" width="280">
              <template slot-scope="scope">
                <el-button
                    type="primary"
                    size="mini"
                    @click="handleEditPassword(scope.row)"
                >
                  编辑密码
                </el-button>
                <el-button
                    type="primary"
                    size="mini"
                    @click="updateTeamUser(scope.row)"
                >
                  编辑
                </el-button>

                <el-button
                    v-if="scope.row.state === 1"
                    size="mini"
                    type="primary"
                    @click="handleStart(scope.row)"
                >
                  启用
                </el-button>
                <el-button
                    v-else
                    size="mini"
                    type="primary"
                    @click="handleDisable(scope.row)"
                >
                  停用
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!--分页-->
        <div class="pagination-box">
          <el-pagination
              :page-sizes="[10, 20, 30, 40]"
              :page-size="form.count"
              :total="totalCount"
              :current-page="form.page"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </div>

      <!--弹出框：添加账号-->
      <el-dialog
          :visible.sync="TeamUserShow"
          :title="dialog.title[dialog.status]"
          :close-on-click-modal="false"
          :modal-append-to-body="false"
          :append-to-body="false"
      >
        <el-form ref="inviteForm" label-width="100px" :model="formInfo" :rules="rules.inviteRules">
          <el-form-item label="姓名:" prop="cname">
            <el-input
                v-model="formInfo.cname"
                show-word-limit
                maxlength="30"
                clearable
                placeholder="请输入姓名"
            />
          </el-form-item>
          <el-form-item label="手机号:" prop="cphone">
            <el-input
                v-model="formInfo.cphone"
                show-word-limit
                maxlength="11"
                clearable
                placeholder="请输入手机号"
                @input="changeValue"
            />
            <!--     size="medium"       -->
          </el-form-item>
          <el-form-item v-if="dialog.status !== 'edit'" label="密码:" prop="password">
            <el-input
                v-model="formInfo.password"
                type="password"
                clearable
                placeholder="请输入密码"
                @input="change($event)"
            />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" plain @click="TeamUserShow = false">取 消</el-button>
          <el-button type="primary" :loading="addUserBtnLoading" @click="dialog.status === 'add' ? addTeamUser() : editConfirm()">
            确定
          </el-button>
        </div>
      </el-dialog>

      <!--弹出框：编辑密码-->
      <el-dialog
          :visible.sync="teamUserEditShow"
          title="编辑密码"
          :close-on-click-modal="false"
          :modal-append-to-body="false"
          :append-to-body="true"
      >
        <div class="mr-70">
          <el-form ref="teamForm" label-width="100px" :model="editPassword" :rules="rules.inviteRules">
            <el-form-item label="新密码:" prop="password">
              <el-input
                  v-model="editPassword.password"
                  type="password"
                  clearable
                  placeholder="请输入新密码"
              />
            </el-form-item>
            <el-form-item label="重复密码:" prop="password2">
              <el-input
                  v-model="editPassword.password2"
                  type="password"
                  clearable
                  placeholder="请输入重复密码"
              />
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" plain @click="teamUserEditShow = false">取 消</el-button>
          <el-button type="primary" :loading="editDetailBtnLoading" @click="editTeamUserRoles">
            确定
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// import API from '@/api';
import valid from '@/utils/validator';
import API from '@/api';

export default {
  name: 'PersonManagement',
  data() {
    const setPassword = (rule, value, callback) => {
      if (value.length > 30 || value.length < 6) {
        callback(new Error('长度在 6 到 30 个字符'));
      }
      callback();
    };
    const validateForget2 = (rule, value, callback) => {
      if (value !== this.editPassword.password) {
        callback(new Error('两次密码不一致'));
      } else {
        callback();
      }
    };
    return {
      teamUser: [],
      TeamUserShow: false,
      teamUserEditShow: false,
      addUserBtnLoading: false,
      editDetailBtnLoading: false,
      formInfo: {
        cname: '',
        cphone: '',
        password: '',
        id: '',
      },
      state: [
        { value: '', label: '全部' },
        { value: 0, label: '启用' },
        { value: 1, label: '停用' },
      ],
      stateId: [
        { value: 0, label: '启用' },
        { value: 1, label: '停用' },
      ],
      listLoading: false,
      // 查询
      form: {
        name: '',
        state: '',
        page: 1,
        count: 10,
      },
      //
      editPassword: {
        password: '',
        password2: '',
      },
      dialog: {
        status: '',
        title: {
          add: '添加维修工',
          edit: '编辑维修工',
        },
      },
      account: '添加维修工',
      rules: {
        inviteRules: {
          cphone: [
            {
              required: true,
              trigger: 'blur',
              validator: (rule, value, callback) => {
                valid.checkNull(rule, value, callback, '请输入手机号', false);
                valid.checkByRegex(rule, value, callback, valid.REG_PHONE, '手机号不合法');
              },
            },
          ],
          cname: [
            { required: true, message: '请输入姓名', trigger: 'change' },
            {
              min: 2, max: 30, message: '长度在 2 到 30 个字符', trigger: 'blur',
            },
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            {
              validator: setPassword,
              trigger: 'change',
            },
          ],
          password2: [
            { required: true, message: '请再次输入密码', trigger: 'blur' },
            { validator: validateForget2, trigger: 'blur' },
          ],
        },
      },
      totalCount: 0,
      buttons: '',
    };
  },
  mounted() {
    this.getTeamUser();
  },
  methods: {
    changeValue(value) {
      this.formInfo.phone = value.replace(/[^\d]/g, '');
    },
    getTeamUser() {
      API.getMaintenanceDate(this.form).then((response) => {
        console.log(response);
        this.teamUser = response.message.data.list;
        this.totalCount = response.message.data.totalCount;
      });
    },
    handleFilter() {
      this.form.count = 10;
      this.form.page = 1;
      this.getTeamUser();
    },
    // 添加弹窗
    addTeamUserShow() {
      this.dialog.status = 'add';
      this.formInfo.cname = '';
      this.formInfo.cphone = '';
      this.formInfo.password = '';
      this.TeamUserShow = true;
      this.$nextTick(() => {
        this.$refs.inviteForm.clearValidate();
      });
    },
    // 添加弹窗确定
    addTeamUser() {
      this.$refs.inviteForm.validate((v) => {
        if (v) {
          console.log(2);
          this.addUserBtnLoading = true;
          const params = JSON.parse(JSON.stringify(this.formInfo));
          console.log(params);
          API.addMaintenanceDate(params).then((res) => {
            console.log(res);
            if (res.message.success === true) {
              this.TeamUserShow = false;
              this.addUserBtnLoading = false;
              this.$message.success('添加成功');
              this.formInfo.cname = '';
              this.formInfo.cphone = '';
              this.formInfo.password = '';
              this.getTeamUser();
            } else {
              this.$message.error(res.message.message);
            }
          }).catch(() => {
            this.addUserBtnLoading = false;
          }).finally(() => {
            this.addUserBtnLoading = false;
          });
        } else {
          return false;
        }
        return v;
      });
    },
    // 编辑按钮
    updateTeamUser(row) {
      this.dialog.status = 'edit';
      this.formInfo = JSON.parse(JSON.stringify(row));
      this.TeamUserShow = true;
      this.$nextTick(() => {
        this.$refs.inviteForm.clearValidate();
      });
    },
    // 编辑弹窗确定
    editConfirm() {
      this.$refs.inviteForm.validate((v) => {
        if (v) {
          console.log(1);
          this.addUserBtnLoading = true;
          const params = JSON.parse(JSON.stringify(this.formInfo));
          console.log(params);
          API.editMaintenancePassword(params, params.id).then((res) => {
            console.log(res);
            if (res.message.success === true) {
              this.TeamUserShow = false;
              this.addUserBtnLoading = false;
              this.$message.success('编辑成功');
              this.formInfo.cname = '';
              this.formInfo.cphone = '';
              this.formInfo.password = '';
              this.getTeamUser();
            } else {
              this.$message.error(res.message.message);
            }
          }).catch(() => {
            this.addUserBtnLoading = false;
          }).finally(() => {
            this.addUserBtnLoading = false;
          });
        } else {
          return false;
        }
        return v;
      });
    },
    // 编辑密码弹窗
    handleEditPassword(row) {
      this.editPassword.password = '';
      this.editPassword.password2 = '';
      console.log(row);
      this.formInfo = JSON.parse(JSON.stringify(row));
      this.teamUserEditShow = true;
      this.$nextTick(() => {
        this.$refs.teamForm.clearValidate();
      });
    },
    // 编辑密码弹窗确定
    editTeamUserRoles() {
      this.$refs.teamForm.validate((v) => {
        if (v) {
          this.editDetailBtnLoading = true;
          console.log(this.editPassword.password);
          API.editMaintenanceDate(this.editPassword, this.formInfo.id).then((res) => {
            console.log(res);
            if (res.message.success === true) {
              this.teamUserEditShow = false;
              this.editDetailBtnLoading = false;
              this.$message.success('编辑成功');
              this.editPassword.password = '';
              this.editPassword.password2 = '';
              this.getTeamUser();
            } else {
              this.$message.error(res.message.message);
            }
          }).catch(() => {
            this.editDetailBtnLoading = false;
          }).finally(() => {
            this.editDetailBtnLoading = false;
          });
        } else {
          return false;
        }
        return v;
      });
    },
    // 启用
    handleStart(row) {
      this.$confirm('确定启用当前维修工账号吗？', '启用账号', {
        closeOnClickModal: false,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            API.editMaintenanceState({ state: 0 }, row.id).then(() => {
              this.$message({
                message: '启用成功',
                showClose: true,
                type: 'success',
              });
              this.getTeamUser();
            });
          });
    },
    // 停用
    handleDisable(row) {
      this.$confirm('确定停用当前维修工账号吗？', '停用账号', {
        closeOnClickModal: false,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            API.editMaintenanceState({ state: 1 }, row.id).then(() => {
              this.$message({
                message: '停用成功',
                showClose: true,
                type: 'success',
              });
              this.getTeamUser();
            });
          });
    },
    handleSizeChange(val) {
      this.form.count = val;
      this.form.page = 1;
      this.getTeamUser();
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.getTeamUser();
    },
    change() {
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
//.page-container{
//  padding: 40px;
//}
//.section{
//  padding: 30px 40px;
//}
.h2Style {
  margin-bottom: 20px;
  //padding-bottom: 20px;
  border-bottom: 1px solid #dcdfe6;
  font-size: 20px;
}
.PersonManagement {
  width: 100%;
}
.control-box1 {
  display: flex;
  justify-content: space-between;
}
.cb-left {
  display: -webkit-box;
}
.control-item {
  margin-left: 15px;
}
::v-deep .form-item{
  display: inline-block;
}
</style>
